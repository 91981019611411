import React, { useState, useEffect } from 'react';
import f from './images/f.png';
// import notification from './images/icons/notification.svg';
// import setting from './images/icons/setting-icon.svg';
// import studioImage from './images/f.png';
// import editIcon from './images/icons/edit.svg';
// import deleteIcon from './images/icons/delete.svg';
// import addIcon from './images/icons/add.svg';
// import studioIconImage from './images/f.png';
import blackImage from './images/blackImage.jpg'
import './css/dashboard.css';
import './css/dashboard-resp.css';


import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import url from './constData';

import { Link, useNavigate } from "react-router-dom";

function ReportPage() {


    const [open, setOpen] = useState(false);
    const [studioList, setStudioList] = useState(null);
    const [filteredList, setFilteredList] = useState(null);
    let reportList = [];
    const navigate = useNavigate();

    const clearAllData = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");

    }



    const fetchData = async () => {
        setOpen(true);
        try {
            const response = await axios.get(`${url}/admin/api/getReport`, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-admin-token": localStorage.getItem("admin-token"),
                }
            });
            // console.log(response.data);
            for (let index = 0; index < response.data.studio.length; index++) {
                reportList.push(response.data.studio[index]);

            }
            for (let index = 0; index < response.data.audition.length; index++) {
                reportList.push(response.data.audition[index]);

            }
            setStudioList(reportList);
            setOpen(false);
        } catch (error) {
            console.log(`The error is -> ${error}`);
            setOpen(false);
        }
    }

    useEffect(() => {
        fetchData();


    }, [])


    const [submenu1, setSubmenu1] = useState(false);
    const [submenu2, setSubmenu2] = useState(false);

    const [editBtn, setEditBtn] = useState(false);
    const [fname, setFname] = useState("");
    const [loc, setLoc] = useState("");
    const [subsName, setSubsName] = useState("");
    const [studDesc, setStudDesc] = useState("");


    const dis1 = {
        display: "block"
    }
    const notDis1 = {
        display: "none"
    }
    const dis2 = {
        display: "block"
    }
    const notDis2 = {
        display: "none"
    }


    const hoverFunction1 = () => {
        if (submenu1 === true) {
            setSubmenu1(false);
        }
        else {
            setSubmenu1(true);
        }
    };
    const hoverFunction2 = () => {
        if (submenu2 === true) {
            setSubmenu2(false);
        }
        else {
            setSubmenu2(true);
        }
    };

    const [details, setDetails] = useState(null);

    const visible = {
        display: "block"
    }
    const notVisible = {
        display: "none"
    }




    const showDetails = (index) => {
        if (details === index) {
            setDetails(null);
        }
        else {
            setDetails(index);
        }

    };

    const editDetails = (studiod) => {
        if (editBtn === true) {
            setEditBtn(false);
            // console.log("true");
        }
        else {
            setStudDesc(studiod.projectDesc);
            setSubsName(studiod.subscriptionName);
            setFname(studiod.fname);
            setLoc(studiod.location)
            setEditBtn(true);

            // console.log("false");
        }
    };


    const UploadDetails = async (studio) => {
        setOpen(true);
        setEditBtn(false);
        setDetails(null);
        setStudioList(null);

        try {

            const resp = await axios.post(`${url}/admin/api/studio/editActive`, {
                userID: studio._id,
                studio: fname,
                subscription: subsName,
                country: loc,
                desc: studDesc,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-admin-token": localStorage.getItem("admin-token"),
                }
            });

            // console.log(`here is the response -> ${resp.data}`);
            setStudioList(resp.data);
            setOpen(false);
        } catch (error) {
            console.log(`here is the error -> ${error}`);
            setOpen(false);
        }
    }


    const DeleteEntry = async (studio) => {
        setOpen(true);
        setEditBtn(false);
        setDetails(null);
        setStudioList(null);
        try {

            const resp = await axios.post(`${url}/admin/api/delete`, {
                userID: studio._id,
                user: "studio",
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-admin-token": localStorage.getItem("admin-token"),
                }
            });
            setStudioList(resp.data);
            setOpen(false);
        } catch (error) {
            console.log(`here is the error -> ${error}`);
            setOpen(false);
        }
    }

    const SearchChange = (event) => {
        setFilteredList(studioList.filter((item) => {
            return item.fname.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        }));
    }


    const aaa = <>

        <select value={subsName} onChange={e => setSubsName(e.target.value)} id="dd">
            <option value="Free">Free</option>
            <option value="Gold">Gold</option>
            <option value="Platinum">Platinum</option>
        </select>
    </>


    const textA = <>
        <textarea value={studDesc} onChange={e => setStudDesc(e.target.value)} style={{ padding: "5px" }} cols="100" rows="10"></textarea>
    </>


    if (studioList === null) {
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="main-yellow">
                    <div className="left-side-menu">
                        <div className="studio-title">
                            {/* <img src={f} alt="profile-pic" /> */}
                            <span className="studio-name">Admin</span>
                        </div>
                        <div className="main-menu">
                            <Link to="/dashboard" className="dashboard-selected">
                                <div className="main-menu-content">
                                    <span> Dashboard</span>
                                </div>
                            </Link>
                            <Link className="inbox-selected sub-menu" onClick={hoverFunction1}>
                                <div className="main-menu-content">
                                    <span>Studio Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu1 ? dis1 : notDis1}>
                                <Link to="/ReportPage">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationstudio">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link className="jobs-selected" onClick={hoverFunction2}>
                                <div className="main-menu-content" >
                                    <span>Audition Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu2 ? dis2 : notDis2}>
                                <Link to="/auditionsectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationaudition">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link to="/promoreq" className="connection-selected">
                                <div className="main-menu-content">
                                    <span>Promotion Request</span>
                                </div>
                            </Link>

                            <Link to='/managementpage' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Management</span>
                                </div>
                            </Link>

                            <Link to='/managePayment' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Payments</span>
                                </div>
                            </Link>
                            <Link to='/report-page' className="report-selected">
                                <div className="main-menu-content">
                                    <span>Report</span>
                                </div>
                            </Link>
                            <Link to='/' className="invite-selected">
                                <div className="main-menu-content" onClick={clearAllData}>
                                    <span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="main-white">
                    <div className="top-div">
                        <input type="text" placeholder="Search here..." />
                        {/* <button className="notification-button"><img src={notification}
                            alt="notification" /></button>
                        <button className="setting-button"><img src={setting} alt="setting" /></button> */}
                    </div>
                </div>
            </>
        )
    }
    else {
        // console.log(studioList);
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="main-yellow">
                    <div className="left-side-menu">
                        <div className="studio-title">
                            {/* <img src={f} alt="profile-pic" /> */}
                            <span className="studio-name">Admin</span>
                        </div>
                        <div className="main-menu">
                            <Link to="/dashboard" className="dashboard-selected">
                                <div className="main-menu-content">
                                    <span> Dashboard</span>
                                </div>
                            </Link>
                            <Link className="inbox-selected sub-menu" onClick={hoverFunction1}>
                                <div className="main-menu-content">
                                    <span>Studio Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu1 ? dis1 : notDis1}>
                                <Link to="/ReportPage">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationstudio">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link className="jobs-selected" onClick={hoverFunction2}>
                                <div className="main-menu-content" >
                                    <span>Audition Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu2 ? dis2 : notDis2}>
                                <Link to="/auditionsectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationaudition">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link to="/promoreq" className="connection-selected">
                                <div className="main-menu-content">
                                    <span>Promotion Request</span>
                                </div>
                            </Link>

                            <Link to='/managementpage' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Management</span>
                                </div>
                            </Link>

                            <Link to='/managePayment' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Payments</span>
                                </div>
                            </Link>
                            <Link to='/report-page' className="report-selected">
                                <div className="main-menu-content">
                                    <span>Report</span>
                                </div>
                            </Link>
                            <Link to='/' className="invite-selected">
                                <div className="main-menu-content" onClick={clearAllData}>
                                    <span>Logout</span>
                                </div>
                            </Link>



                        </div>
                    </div>
                </div>
                <div className="main-white">
                    <div className="top-div">
                        <input type="text" placeholder="Search here..." onChange={SearchChange} />
                        {/* <button className="notification-button"><img src={notification}
                            alt="notification" /></button>
                        <button className="setting-button"><img src={setting} alt="setting" /></button> */}
                    </div>
                    <div className="main-white-left1">
                        <section className="popular-studios1">

                            <div className="pop-stud1">
                                <h2>Popular Studios</h2>
                                <div className="icons-area">
                                    {/* <img src={editIcon} alt="edit-icon" />
                                    <img src={deleteIcon} alt="edit-icon" />
                                    <img src={addIcon} alt="edit-icon" /> */}
                                </div>
                            </div>
                            <hr />
                            <div className="p-s-title">
                                <span>Studio</span>
                                <span>Created By</span>
                                <span>Subscription</span>
                                <span>Country</span>
                                <span>Followers</span>
                                <span>Posted Jobs</span>
                            </div>
                            <div className="scroll-area">
                                {
                                    studioList.length === 0 ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", fontSize: "40px", fontWeight: "bolder", color: "grey" }}>No Data Available</div> :
                                        filteredList === null ? studioList.map((studio, index) =>
                                            <>
                                                <div className="p-s-studio-details" key={index} onClick={() => showDetails(index)}>
                                                    <span>{studio.fname}</span>
                                                    <span className='p-s-s-i'><img src={studio.profilePic === "" ? blackImage : studio.profilePic} alt="profilePic" /> {studio.fname}</span>
                                                    <span>{studio.subscriptionName}</span>
                                                    <span>{studio.location}</span>
                                                    <span>{studio.followers.length}</span>
                                                    <span>{studio.post.length}</span>
                                                </div>

                                                <div className="studio-desc-admin" style={details === index ? visible : notVisible}>
                                                    <section className="desc-first">
                                                        <div className="st-name">
                                                            <div className="st-name-img">
                                                                <img src={studio.profilePic === "" ? blackImage : studio.profilePic} alt="studio-icon" />
                                                            </div>
                                                            <div className="st-name-name">
                                                                {
                                                                    editBtn ? <input style={{ border: "1px solid black", width: "80%", height: "1rem", margin: "0", fontSize: "1.2rem" }} type="text" value={fname} onChange={e => setFname(e.target.value)} /> : <span>{studio.fname}</span>
                                                                }
                                                                {
                                                                    editBtn ? <input type="text" style={{ border: "1px solid black", width: "80%", height: "1rem", margin: "0", fontSize: "1.2rem" }} value={loc} onChange={e => setLoc(e.target.value)} /> : <span>({studio.location})</span>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="subs-std">
                                                            <h3>Subscription: {editBtn ? aaa : studio.subscriptionName}</h3>
                                                            <span>{studio.daysLeft} Days left</span>
                                                        </div>
                                                    </section>
                                                    <section className="desc-second">
                                                        <span>Studio: {studio.fname}</span>
                                                        <span>Followers: {studio.followers.length}</span>
                                                    </section>
                                                    <section className="desc-third">
                                                        {editBtn ? textA : <pre>{studio.projectDesc}</pre>}
                                                    </section>
                                                    {editBtn ? <section className="desc-fourth">
                                                        <button className="btn" onClick={() => editDetails(studio)}>{editBtn ? "Cancel" : "Edit"}</button>
                                                        <button className="btn" onClick={() => DeleteEntry(studio)}>Delete</button>
                                                        <button className="btn" onClick={() => UploadDetails(studio)}>Update</button>
                                                    </section> : <section className="desc-fourth">
                                                        <button className="btn" onClick={() => editDetails(studio)}>{editBtn ? "Cancel" : "Edit"}</button>
                                                        <button className="btn" onClick={() => DeleteEntry(studio)}>Delete</button>

                                                    </section>}

                                                </div>
                                            </>
                                        ) : filteredList.map((studio, index) =>
                                            <>
                                                <div className="p-s-studio-details" key={index} onClick={() => showDetails(index)}>
                                                    <span>{studio.fname}</span>
                                                    <span className='p-s-s-i'><img src={studio.profilePic === "" ? blackImage : studio.profilePic} alt="profilePic" /> {studio.fname}</span>
                                                    <span>{studio.subscriptionName}</span>
                                                    <span>{studio.location}</span>
                                                    <span>{studio.followers.length}</span>
                                                    <span>{studio.post.length}</span>
                                                </div>

                                                <div className="studio-desc-admin" style={details === index ? visible : notVisible}>
                                                    <section className="desc-first">
                                                        <div className="st-name">
                                                            <div className="st-name-img">
                                                                <img src={studio.profilePic} alt="studio-icon" />
                                                            </div>
                                                            <div className="st-name-name">
                                                                {
                                                                    editBtn ? <input style={{ border: "1px solid black", width: "80%", height: "1rem", margin: "0", fontSize: "1.2rem" }} type="text" value={fname} onChange={e => setFname(e.target.value)} /> : <span>{studio.fname}</span>
                                                                }
                                                                {
                                                                    editBtn ? <input type="text" style={{ border: "1px solid black", width: "80%", height: "1rem", margin: "0", fontSize: "1.2rem" }} value={loc} onChange={e => setLoc(e.target.value)} /> : <span>({studio.location})</span>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="subs-std">
                                                            <h3>Subscription: {editBtn ? aaa : studio.subscriptionName}</h3>
                                                            <span>{studio.daysLeft} Days left</span>
                                                        </div>
                                                    </section>
                                                    <section className="desc-second">
                                                        <span>Studio: {studio.fname}</span>
                                                        <span>Followers: {studio.followers.length}</span>
                                                    </section>
                                                    <section className="desc-third">
                                                        {editBtn ? textA : <pre>{studio.projectDesc}</pre>}
                                                    </section>
                                                    {editBtn ? <section className="desc-fourth">
                                                        <button className="btn" onClick={() => editDetails(studio)}>{editBtn ? "Cancel" : "Edit"}</button>
                                                        <button className="btn" onClick={() => DeleteEntry(studio)}>Delete</button>
                                                        <button className="btn" onClick={() => UploadDetails(studio)}>Update</button>
                                                    </section> : <section className="desc-fourth">
                                                        <button className="btn" onClick={() => editDetails(studio)}>{editBtn ? "Cancel" : "Edit"}</button>
                                                        <button className="btn" onClick={() => DeleteEntry(studio)}>Delete</button>

                                                    </section>}

                                                </div>
                                            </>
                                        )
                                }
                            </div>
                        </section>
                    </div>

                </div>
            </>
        )
    }



}

export default ReportPage
