import React, { useState, useEffect } from 'react';
import f from './images/f.png';
// import graph from './images/graph2.svg';
// import notification from './images/icons/notification.svg';
// import setting from './images/icons/setting-icon.svg';
import studioImage from './images/f.png';
import './css/dashboard.css';
import './css/dashboard-resp.css';

import { BarChart, Bar, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import url from './constData';

import { Link, useNavigate } from "react-router-dom";




function Dashboard() {

    const [dashData, setDashData] = useState(null);
    const [studioData, setStudioData] = useState(null);
    const [auditionData, setAuditionData] = useState(null);
    const [popStudios, setPopStudios] = useState(null);

    const navigate = useNavigate();

    const clearAllData = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");

    }


    const [open, setOpen] = useState(false);
    const [token, setToken] = useState("");

    const fetchData = async () => {
        setOpen(true);
        try {
            const response = await axios.get(`${url}/admin/api/getAllData`, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-admin-token": localStorage.getItem("admin-token"),
                }
            });
            // console.log(`here is your data -> ${JSON.stringify(response.data)}`);

            setToken(response.data.token);
            localStorage.setItem("admin-token", response.data.token);
            setDashData(response.data);
            setPopStudios(response.data.popStudios);
            setAuditionData(response.data.auditionData);
            setStudioData(response.data.studioData);
            // console.log(`here is the length of dashData - ${dashData}`);
            setOpen(false);


        } catch (error) {
            console.log(`There is some error: ${error}`);
            setOpen(false);
        }
    }

    useEffect(() => {
        fetchData();


    }, [])






    const [submenu1, setSubmenu1] = useState(false);
    const [submenu2, setSubmenu2] = useState(false);


    const dis1 = {
        display: "block"
    }
    const notDis1 = {
        display: "none"
    }
    const dis2 = {
        display: "block"
    }
    const notDis2 = {
        display: "none"
    }


    const hoverFunction1 = () => {
        if (submenu1 === true) {
            setSubmenu1(false);
        }
        else {
            setSubmenu1(true);
        }
    };
    const hoverFunction2 = () => {
        if (submenu2 === true) {
            setSubmenu2(false);
        }
        else {
            setSubmenu2(true);
        }
    };


    if (dashData === null) {
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="main-yellow">
                    <div className="left-side-menu">
                        <div className="studio-title">
                            {/* <img src={f} alt="profile-pic" /> */}
                            <span className="studio-name">Admin</span>
                        </div>
                        <div className="main-menu">
                            <Link to="/dashboard" className="dashboard-selected">
                                <div className="main-menu-content">
                                    <span> Dashboard</span>
                                </div>
                            </Link>
                            <Link className="inbox-selected sub-menu" onClick={hoverFunction1}>
                                <div className="main-menu-content">
                                    <span>Studio Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu1 ? dis1 : notDis1}>
                                <Link to="/studiosectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationstudio">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link className="jobs-selected" onClick={hoverFunction2}>
                                <div className="main-menu-content" >
                                    <span>Audition Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu2 ? dis2 : notDis2}>
                                <Link to="/auditionsectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationaudition">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link to="/promoreq" className="connection-selected">
                                <div className="main-menu-content">
                                    <span>Promotion Request</span>
                                </div>
                            </Link>

                            <Link to='/managementpage' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Management</span>
                                </div>
                            </Link>
                            <Link to='/managePayment' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Payments</span>
                                </div>
                            </Link>
                            <Link to='/report-page' className="report-selected">
                                <div className="main-menu-content">
                                    <span>Report</span>
                                </div>
                            </Link>

                            <Link to='/' className="logout-selected">
                                <div className="main-menu-content" onClick={clearAllData}>
                                    <span>Logout</span>
                                </div>
                            </Link>



                        </div>
                    </div>
                </div>
                <div className="main-white">
                    <div className="top-div">
                        {/* <input type="text" placeholder="Search here..." />
                        <button className="notification-button"><img src={notification}
                            alt="notification" /></button>
                        <button className="setting-button"><img src={setting} alt="setting" /></button> */}
                    </div>

                </div>
            </>
        );
    }
    else {
        // console.log(`here is dash data => ${dashData.auditionData.aActiveUsers}`);
        // console.log(popStudios[0].fname);
        // console.log(`graph data -> ${studioData.studioGraph.janStudio}`);

        const data = [
            { name: 'Jan', studio: studioData.studioGraph.janStudio, audition: auditionData.auditionGraph.janAudition },
            { name: 'Feb', studio: studioData.studioGraph.febStudio, audition: auditionData.auditionGraph.febAudition },
            { name: 'Mar', studio: studioData.studioGraph.marStudio, audition: auditionData.auditionGraph.marAudition },
            { name: 'Apr', studio: studioData.studioGraph.aprStudio, audition: auditionData.auditionGraph.aprAudition },
            { name: 'May', studio: studioData.studioGraph.mayStudio, audition: auditionData.auditionGraph.mayAudition },
            { name: 'Jun', studio: studioData.studioGraph.junStudio, audition: auditionData.auditionGraph.junAudition },
            { name: 'Jul', studio: studioData.studioGraph.julStudio, audition: auditionData.auditionGraph.julAudition },
            { name: 'Aug', studio: studioData.studioGraph.augStudio, audition: auditionData.auditionGraph.augAudition },
            { name: 'Sep', studio: studioData.studioGraph.sepStudio, audition: auditionData.auditionGraph.sepAudition },
            { name: 'Oct', studio: studioData.studioGraph.octStudio, audition: auditionData.auditionGraph.octAudition },
            { name: 'Nov', studio: studioData.studioGraph.novStudio, audition: auditionData.auditionGraph.novAudition },
            { name: 'Dec', studio: studioData.studioGraph.decStudio, audition: auditionData.auditionGraph.decAudition },
        ];

        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="main-yellow">
                    <div className="left-side-menu">
                        <div className="studio-title">
                            {/* <img src={f} alt="profile-pic" /> */}
                            <span className="studio-name">Admin</span>
                        </div>
                        <div className="main-menu">
                            <Link to="/dashboard" className="dashboard-selected">
                                <div className="main-menu-content">
                                    <span> Dashboard</span>
                                </div>
                            </Link>
                            <Link className="inbox-selected sub-menu" onClick={hoverFunction1}>
                                <div className="main-menu-content">
                                    <span>Studio Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu1 ? dis1 : notDis1}>
                                <Link to="/studiosectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/studiosubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationstudio">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link className="jobs-selected" onClick={hoverFunction2}>
                                <div className="main-menu-content" >
                                    <span>Audition Section</span>
                                </div>
                            </Link>
                            <div className="dropdown-menu" style={submenu2 ? dis2 : notDis2}>
                                <Link to="/auditionsectionactive">
                                    <div className="drop-active">
                                        <span>Active User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsectioninactive">
                                    <div className="drop-active">
                                        <span>Inactive User</span>
                                    </div>
                                </Link>
                                <Link to="/auditionsubscription">
                                    <div className="drop-active">
                                        <span>Subscription</span>
                                    </div>
                                </Link>
                                {/* <Link to="/studioquery">
                                    <div className="drop-active">
                                        <span>Query</span>
                                    </div>
                                </Link>
                                <Link to="/notificationaudition">
                                    <div className="drop-active">
                                        <span>Notification</span>
                                    </div>
                                </Link> */}
                            </div>
                            <Link to="/promoreq" className="connection-selected">
                                <div className="main-menu-content">
                                    <span>Promotion Request</span>
                                </div>
                            </Link>

                            <Link to='/managementpage' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Management</span>
                                </div>
                            </Link>
                            <Link to='/managePayment' className="invite-selected">
                                <div className="main-menu-content">
                                    <span>Payments</span>
                                </div>
                            </Link>
                            <Link to='/report-page' className="report-selected">
                                <div className="main-menu-content">
                                    <span>Report</span>
                                </div>
                            </Link>
                            <Link to='/' className="invite-selected">
                                <div className="main-menu-content" onClick={clearAllData}>
                                    <span>Logout</span>
                                </div>
                            </Link>



                        </div>
                    </div>
                </div>
                <div className="main-white">
                    <div className="top-div11">
                        <div className='graphColorArea'>
                            <div className='studioColorName'>
                                <span>Studio</span>
                                <div className="squareColorStud"></div>
                            </div>
                            <div className="auditionColorName">
                                <span>Audition</span>
                                <div className="squareColorAudi"></div>
                            </div>
                        </div>
                        {/* <input type="text" placeholder="Search here..." />
                        <button className="notification-button"><img src={notification}
                            alt="notification" /></button>
                        <button className="setting-button"><img src={setting} alt="setting" /></button> */}
                    </div>
                    <div className="main-white-left" style={{ display: open ? "none" : "block" }}>
                        <div className="graph-area">
                            {/* <img src={graph} alt="graph" className="graph-image" /> */}
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={data}>
                                    <Bar dataKey="studio" fill="#3cbcfb" />

                                    <Bar dataKey="audition" fill="#6775ed" />
                                    <CartesianGrid stroke="#ccc" gridBackground={{ fill: "#8884d8" }} />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <section className="overview">
                            <div className="over">
                                <h2>Overview</h2>
                            </div>
                            <hr />
                            <div className="overview-studio">
                                <div className="studio">
                                    <h3>Studio</h3>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>Active user</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {studioData.sActiveUsers}
                                    </div>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>Inactive user</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {studioData.sInactiveUsers}
                                    </div>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>New user</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {studioData.sNewUsers}
                                    </div>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>Users Left</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {studioData.sLeftUsers}
                                    </div>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>Subscribers</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {studioData.sSubscribers}
                                    </div>
                                </div>

                            </div>
                            <div className="overview-studio">
                                <div className="studio">
                                    <h3>Audition</h3>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>Active user</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {auditionData.aActiveUsers}
                                    </div>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>Inactive user</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {auditionData.aInactiveUsers}
                                    </div>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>New user</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {auditionData.aNewUsers}
                                    </div>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>Users Left</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {auditionData.aLeftusers}
                                    </div>
                                </div>
                                <div className="studio-user-details">
                                    <div className="active-user">
                                        <span>Subscribers</span>
                                    </div>
                                    <hr />
                                    <div className="number">
                                        {auditionData.aSubscribers}
                                    </div>
                                </div>

                            </div>
                        </section>
                        <section className="popular-studios">
                            <div className="pop-stud">
                                <h2>Popular Studios</h2>
                            </div>
                            <hr />
                            <div className="p-s-title">
                                <span>Studio</span>
                                <span>Created By</span>
                                <span>Subscription</span>
                                <span>Country</span>
                                <span>Followers</span>
                                <span>Posted Jobs</span>
                            </div>
                            {popStudios.map((studio) =>
                                <div style={{ maxHeight: "20vh", overflowY: "auto" }}>
                                    <div className="p-s-studio-details">
                                        <span className='p-s-s-i'><img src={studio.profilePic === "" ? studioImage : studio.profilePic} alt="" /> {studio.fname}</span>
                                        <span className='p-s-s-i'><img src={studio.profilePic === "" ? studioImage : studio.profilePic} alt="" /> {studio.fname}</span>
                                        <span>{studio.subscriptionName}</span>
                                        <span>{studio.location}</span>
                                        <span>{studio.followers.length}</span>
                                        <span>{studio.post.length}</span>
                                    </div>
                                </div>
                            )}
                            {/* <div style={{ maxHeight: "30vh", overflowY: "auto" }}>
                                <div className="p-s-studio-details">
                                    <span>Fuga et ut Studio</span>
                                    <span className='p-s-s-i'><img src={studioImage} alt="" /> Fuga et ut Studio</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Fuga et ut Studio</span>
                                    <span className='p-s-s-i'><img src={studioImage} alt="" /> Fuga et ut Studio</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Fuga et ut Studio</span>
                                    <span className='p-s-s-i'><img src={studioImage} alt="" /> Fuga et ut Studio</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Fuga et ut Studio</span>
                                    <span className='p-s-s-i'><img src={studioImage} alt="" /> Fuga et ut Studio</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                                <div className="p-s-studio-details">
                                    <span>Fuga et ut Studio</span>
                                    <span className='p-s-s-i'><img src={studioImage} alt="" /> Fuga et ut Studio</span>
                                    <span>Gold</span>
                                    <span>Zimbabwe</span>
                                    <span>456K</span>
                                    <span>456</span>
                                </div>
                            </div> */}
                        </section>
                    </div>

                </div>
            </>
        );
    }



}

export default Dashboard
